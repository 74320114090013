import React, { memo, useEffect, useState } from "react";
import { TodoistApi } from '@doist/todoist-api-typescript'

const api = new TodoistApi('8d7b916e775356044ad015deff7b047f49d039fb')

const AppPure = () => {
  const [items, setItems] = useState([])

  useEffect(() => {
    fetch('https://api.todoist.com/sync/v9/completed/get_all?project_id=1525845712&limit=100&annotate_notes=true', { headers: {
        Authorization: "Bearer 8d7b916e775356044ad015deff7b047f49d039fb"
      } })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        setItems(data.items)
      })
      .catch((error) => console.log(error))
  }, [])

  console.log(items)

  return (
    <div className="App">
      {items.map((item) => (<div key={item.id}>{item.content}</div>))}
    </div>
  );
}

export default memo(AppPure);
