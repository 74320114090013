import React, { memo } from "react";

import SmartTV from "./SmartTV";
import ClientApi from "./ClientApi";
import Todoist from "./Todoist";
import TodoistOld from "./Todoist.old";

const AppPure = () => (
  <>
    <SmartTV />
    {/*<Todoist />*/}
    {/*<TodoistOld />*/}
  </>
);

export default memo(AppPure);
