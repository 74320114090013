import React, { memo, useEffect, useState } from "react";
// import { TodoistApi } from "@doist/todoist-api-typescript";
import {
  filter,
  not,
  map,
  equals,
  sortBy,
  prop,
  path,
  reverse,
  compose,
  includes,
  and,
} from "ramda";

// const api = new TodoistApi("8d7b916e775356044ad015deff7b047f49d039fb");

const desc = (a) => a;

const sort = sortBy(compose(desc, path(["items", [0], "completed_at"])));
const sortDev = sortBy(compose(desc, prop("completed_at")));

const handleA = (otherItems) => (item) => {
  const filteredData = filter(
    (aaa) => equals(item.id, aaa.parent_id),
    otherItems
  );
  const preparedFilteredData = sortBy(prop("added_at"), filteredData);

  return {
    ...item,
    items: reverse(
      sortBy(prop("completed_at"), map(handleA(otherItems), filteredData))
    ),
  };
};

const renderItems = (items) =>
  items.map((item) => (
    <div key={item.id}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            color: and(not(item.checked), includes("Waiting", prop("labels", item)))
              ? "orange"
              : item.checked
              ? "green"
              : "red",
            marginRight: 8,
            fontSize: 30,
            lineHeight: 0.6,
          }}
        >
          •
        </div>
        {item.content}
      </div>
      <div style={{ marginLeft: 50 }}>{renderItems(item.items)}</div>
    </div>
  ));

const TodoistPure = () => {
  // const [items, setItems] = useState([]);
  const [devItems, setDevItems] = useState([]);

  useEffect(() => {
    fetch(
      "https://monkfish-app-8v7l7.ondigitalocean.app/tasks?project_id=1525845712&limit=200"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        const allItems = data;

        const headItems = filter((item) => not(item.parent_id), allItems);
        const otherItems = filter((item) => item.parent_id, allItems);

        const c = map(handleA(otherItems), headItems);

        // setItems(data);
        setDevItems(reverse(sortDev(c)));
        // setDevItems(c);
      })
      .catch((error) => console.log(error));
  }, []);

  return <div className="App">{renderItems(devItems)}</div>;
};

export default memo(TodoistPure);
