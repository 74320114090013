import React, {memo, useCallback, useEffect, useState} from "react";
import {
  includes,
  prop,
  compose,
  values,
  reject,
  not,
  map,
  mapObjIndexed,
  equals,
  omit,
  keys,
  pathOr,
} from "ramda";
import { mapIndexed, isNotNilOrEmpty } from "ramda-adjunct";

const BRANDS = {
  alphaott: {
    name: "AlphaOTT",
    url: "https://api.production.alphaott.com/client/api/config.json",
  },
  "alphaott-test": {
    name: "AlphaOTT-Test",
    url: "https://mw.alphaott.test.alphaott.com/client/api/config.json",
  },
  // cloodtv: {
  //   name: "CloodTV",
  //   url: "https://api.cloodtv.com/client/api/config.json",
  // },
  ellastvmax: {
    name: "EllasTVMax",
    url: "https://api.ellastvmax.com/client/api/config.json",
  },
  ellastvmaxau: {
    name: "EllasTVMaxAu",
    url: "https://api.ellastvmax.com.au/client/api/config.json",
  },
  // futuretv: {
  //   name: "FutureTVNow",
  //   url: "https://api.futuretvnow.com/client/api/config.json",
  // },
  // ilinkstv: {
  //   name: "IlinksTV",
  //   url: "https://api.ilinks.tv/client/api/config.json",
  // },
  ittv: {
    name: "ITTV",
    url: "https://api.ittv.com.br/client/api/config.json",
  },
  miplay: {
    name: "MiPlay",
    url: "https://api.miplay.cl/client/api/config.json",
  },
  tvanetplay: {
    name: "TvaNetPlay",
    url: "https://api.tvanetplay.com.br/client/api/config.json",
  },
  zaaptv: {
    name: "ZaapTV",
    url: "https://mw.zaaptv.com/client/api/config.json",
  },
  profoxy: {
    name: "ProFoxy",
    url: "https://api.profoxy.com/client/api/config.json",
  },
};

const SmartTVPure = () => {
  const [items, setItems] = useState(BRANDS);

  const handleUpdateData = useCallback(() => mapObjIndexed((item, brandId) => {
      fetch(item.url)
        .then((response) => response.json())
        .then((data) => {
          const smartTvLinks = prop("smarttv", data);
          const smartTvVersions = omit(["subscriptionUrl"], smartTvLinks);
          const versions = map((key) => {
            const linkArray = smartTvVersions[key].split("/");
            const appVersion = linkArray[linkArray.length - 2];

            return { bootloader: key, appVersion };
          }, keys(smartTvVersions));
          const webPlayer = pathOr(
            "videojs",
            ["ui", "player", "videoPlayer"],
            data
          );
          const player = pathOr(
            "shakaplayer",
            ["platform", "smarttv", "player", "videoPlayer"],
            data
          );
          const forceReadyPlayerHack = pathOr(
            false,
            [
              "platform",
              "smarttv",
              "player",
              "forceReadyPlayerHack",
              "enabled",
            ],
            data
          );
          const httpForSourceLink = pathOr(
            false,
            ["platform", "smarttv", "player", "httpForSourceLink", "enabled"],
            data
          );

          const languageSettings = pathOr(
            true,
            ["platform", "smarttv", "ui", "settings", "languages", "enabled"],
            data
          );

          const preferredLanguages = pathOr(
            true,
            ["platform", "smarttv", "ui", "settings", "preferredLanguages", "enabled"],
            data
          );

          const parentalControlSettings = pathOr(
            true,
            ["platform", "smarttv", "ui", "settings", "parentalControl", "enabled"],
            data
          );

          setItems((oldItems) => ({
            ...oldItems,
            [brandId]: {
              ...item,
              versions,
              webPlayer,
              player,
              forceReadyPlayerHack,
              httpForSourceLink,
              languageSettings,
              preferredLanguages,
              parentalControlSettings
            },
          }));
        })
        .catch((error) => console.log(error));
    }, items), [])

  useEffect(() => {
    handleUpdateData()

    // setInterval(() => handleUpdateData(), 5000)
  }, [handleUpdateData]);

  return (
    <div style={{ width: 1500 }}>
      {compose(
        values,
        map((item) => (
          <div key={item.name}>
            <div style={{display: "flex"}}>
              <div style={{flex: 1}}>{item.name}</div>
              <div style={{flex: 1}}>
                {isNotNilOrEmpty(item.versions) &&
                  mapIndexed(
                    (item, index) => (
                      <div key={index} style={{display: "flex"}}>
                        <div style={{flex: 1}}>{item.bootloader}</div>
                        <div style={{flex: 1}}>{item.appVersion}</div>
                      </div>
                    ),
                    item.versions
                  )}
              </div>
              <div style={{flex: 1}}>{item.webPlayer}</div>
              <div style={{flex: 1}}>{item.player}</div>
              <div style={{flex: 1}}>
                forceReadyPlayerHack: {String(item.forceReadyPlayerHack)}
              </div>
              <div style={{flex: 1}}>
                httpForSourceLink: {String(item.httpForSourceLink)}
              </div>
              <div style={{flex: 1}}>
                languageSettings: {String(item.languageSettings)}
              </div>
              <div style={{flex: 1}}>
                preferredLanguageSettings: {String(item.preferredLanguages)}
              </div>
              <div style={{flex: 1}}>
                parentalControlSettings: {String(item.parentalControlSettings)}
              </div>
            </div>
            <div
              style={{height: 1, width: "100%", backgroundColor: "#000"}}
            />
          </div>
        ))
      )(items)}
    </div>
  );
};

export default memo(SmartTVPure);
